const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}
const _darkGrayColor = '#3b3b3b';
const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',
  primaryColor: '#c1272d',
  bgColor: '#ebebeb',
  redColor : '#c1272d',
  orangeColor : '#f15a24',
  blueColor : '#0071bc',
  darkBlueColor : '#18547c',
  darkGrayColor : _darkGrayColor,
  fontColor: _darkGrayColor,
  ...overloading
}

export default variables;